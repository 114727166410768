import Link from 'next/link'
import { Logo404Icon } from 'components/Icons/Logo404Icon'
import { FONT_SIZE, FONT_SIZE_14 } from 'styles/theme'
import { useStyles } from './404.styles'

export const Page404 = ({
    links,
    isMobileWidth
}: {
    links: { href: string; label: string }[]
    isMobileWidth: boolean
}) => {
    const { classes } = useStyles()
    return (
        <div className={classes.pageWrapper}>
            <div className={classes.errorContentContainer}>
                <div className={classes.logoContainer}>
                    <Logo404Icon
                        height={isMobileWidth ? '121px' : '194'}
                        width={isMobileWidth ? '203px' : '320px'}
                    />
                </div>
                <div
                    className={classes.copyContainer}
                    style={{
                        fontSize: isMobileWidth ? FONT_SIZE_14 : FONT_SIZE,
                        width: isMobileWidth ? 343 : 'unset'
                    }}
                >
                    {`The page you are looking for does not exist or is unavailable right now.
                        Here are some links that might help you find what you're looking for.`}
                </div>
                <div className={classes.linksContainer}>
                    {links.map((link, index) => {
                        return (
                            <div
                                className={classes.link}
                                key={link.href}
                                style={{
                                    marginLeft: !isMobileWidth && index !== 0 ? '15px' : '10px',
                                    marginRight:
                                        !isMobileWidth && index !== links.length - 1
                                            ? '15px'
                                            : '10px'
                                }}
                            >
                                <Link
                                    className="Something"
                                    href={link.href}
                                >
                                    <a
                                        className="my-className"
                                        style={{
                                            fontSize: isMobileWidth ? FONT_SIZE_14 : FONT_SIZE
                                        }}
                                    >
                                        {link.label}
                                    </a>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
