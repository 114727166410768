import { FC } from 'react'
import { COLOR_BLACK } from 'styles/theme'

type Props = {
    fill?: string
    height?: string
    width?: string
}

export const Logo404Icon: FC<Props> = ({
    fill = COLOR_BLACK,
    height = '194',
    width = '321'
}: Props): JSX.Element => (
    <svg
        fill={fill}
        height={height}
        width={width}
        viewBox="0 0 321 194"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.30171 134.545V115.17L56.8813 38.6364H73.5858V65.4545H63.6994L33.0744 113.92V114.83H102.109V134.545H8.30171ZM64.154 155V128.636L64.6085 120.057V38.6364H87.6767V155H64.154ZM219.602 134.545V115.17L268.181 38.6364H284.886V65.4545H274.999L244.374 113.92V114.83H313.409V134.545H219.602ZM275.454 155V128.636L275.909 120.057V38.6364H298.977V155H275.454Z"
            fill="black"
        />
        <g clipPath="url(#clip0_2070_439)">
            <path
                d="M188.856 48.3037C181.545 41.3772 172.065 36.9291 162.606 37.0491C162.262 37.0309 161.914 37.02 161.573 37.0127C159.796 36.9691 158.048 37.0382 156.336 37.209C157.842 39.4185 159.348 41.6316 160.844 43.8484C162.768 46.7011 164.629 49.5938 166.557 52.4465C169.412 56.6656 172.38 60.8121 175.143 65.0893C177.06 68.0547 178.896 71.0964 180.442 74.2616C182.377 78.2227 181.582 82.3256 180.16 86.2431C177.998 92.1993 174.531 97.3596 169.727 101.561C164.882 105.794 159.616 109.537 153.049 110.271C148.109 110.824 143.455 109.857 140.135 105.314C136.569 100.441 137.046 95.379 138.695 90.1896C140.813 83.5321 145.031 78.2046 150.286 73.7311C154.405 70.2206 159.019 67.4405 164.666 66.3213C164.266 65.3655 164.043 64.4933 163.574 63.7738C161.247 60.1943 158.854 56.6511 156.486 53.097C154.584 50.2406 152.694 47.3734 150.781 44.5207C149.593 42.7473 148.406 40.9775 147.215 39.2077C126.708 46.2178 114.259 68.916 121.647 90.9927C129.031 113.062 145.438 134.754 158.073 153.269C159.374 155.046 161.54 155.05 162.837 153.284C175.488 134.801 192.29 112.564 199.502 90.3495C204.332 75.4681 200.26 59.1041 188.86 48.3037H188.856Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_2070_439">
                <rect
                    width="82"
                    height="117.605"
                    fill="white"
                    transform="translate(119.5 37)"
                />
            </clipPath>
        </defs>
    </svg>
)
