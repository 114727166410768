import { CssBaseline } from '@mui/material'
import { Page404 } from 'components/404'
import { FooterComponent } from 'components/Footer'
import { useNav, useWindowWidth } from 'hooks'
import type { NextPage } from 'next'
import Head from 'next/head'

const errorPageLinks = [
    {
        href: '/',
        label: 'Home'
    },
    {
        href: '/faq',
        label: 'FAQ'
    },
    {
        href: '/contact-us',
        label: 'Contact Us'
    },
    {
        href: '/privacy-policy',
        label: 'Privacy Policy'
    }
]

const Custom404: NextPage<any> = () => {
    const { currentPathName, isNotHomePage } = useNav()
    const { isMobileWidth } = useWindowWidth()
    return (
        <>
            <Head>
                <title>404</title>
            </Head>
            <CssBaseline />
            <Page404
                isMobileWidth={isMobileWidth}
                links={errorPageLinks}
            />
            <FooterComponent
                isF1={false}
                isMobileWidth={isMobileWidth}
                isNotHomePage={isNotHomePage}
                routerPathName={currentPathName}
            />
        </>
    )
}

export default Custom404
