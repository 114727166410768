import { COLOR_BLACK, COLOR_PRIMARY_600, FONT_SIZE_14, SIZE_32 } from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    copyContainer: {
        color: COLOR_BLACK,
        marginBottom: SIZE_32,
        maxWidth: '400px',
        textAlign: 'center'
    },
    errorContentContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        marginTop: '-80px'
    },
    linksContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    link: {
        '& > a': {
            color: COLOR_PRIMARY_600,
            fontSize: FONT_SIZE_14,
            fontWeight: 500,
            textDecoration: 'unset',
            '&:hover': {
                color: COLOR_PRIMARY_600,
                cursor: 'pointer'
            }
        }
    },
    logoContainer: {
        marginBottom: 0
    },
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        marginTop: '80px',
        width: '100%'
    }
})
